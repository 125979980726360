import styled from 'styled-components';

export const SummaryContainer = styled.div`
  font-size: 16px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  text-align: left;
`;

export const Quote = styled.div`
  font-size: 20px;
  font-style: italic;
  margin-bottom: 16px;
  text-align: center;
`;

export const Text = styled.p`
  margin: 0;
`;
