export const skills = [
    {
        name: 'JavaScript',
        progress: 88,
    },
    {
        name: 'AWS',
        progress: 80,
    },
    {
        name: 'Google Firebase',
        progress: 80,
    },
    {
        name: 'NodeJS',
        progress: 70,
    },
    {
        name: 'Java',
        progress: 80,
    },
    {
        name: 'Flutter',
        progress: 59,
    },
    {
        name: 'CSS',
        progress: 80,
    },
    {
        name: 'ReactJS',
        progress: 98,
    },
    {
        name: 'Styled-Component',
        progress: 80,
    },
    {
        name: 'React Native',
        progress: 63,
    },
    {
        name: 'Bootstrap',
        progress: 95,
    },
    {
        name: 'Thymeleaf',
        progress: 70,
    },
    {
        name: 'Python',
        progress: 85,
    },
    {
        name: 'Typescript',
        progress: 60,
    },
    {
        name: 'Google Firestore',
        progress: 72,
    },
    {
        name: 'OracleDB',
        progress: 85,
    },
    {
        name: 'VSCode',
        progress: 85,
    },
    {
        name: 'Docker-compose',
        progress: 79,
    },
    {
        name: 'MongoDB',
        progress: 85,
    },
    {
        name: 'MySQL',
        progress: 98,
    },
    {
        name: 'Docker',
        progress: 74,
    },
    {
        name: 'Github',
        progress: 83,
    },
    {
        name: 'EJS',
        progress: 80,
    },
    {
        name: 'Postman',
        progress: 88,
    },
    {
        name: 'C#',
        progress: 50,
    },
    {
        name: 'AWS Bucket S3',
        progress: 77,
    },
    {
        name: 'Netlify',
        progress: 85,
    },
    {
        name: 'Jenkins',
        progress: 81,
    },
    {
        name: 'Expo Dev',
        progress: 77,
    },
    {
        name: 'AWS SQS Queues',
        progress: 89,
    },
    {
        name: 'AWS EC2',
        progress: 90,
    },
    {
        name: 'AWS RDS',
        progress: 88,
    },
    {
        name: 'JIRA Software',
        progress: 99,
    },
];