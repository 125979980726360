import React, { useState, useRef, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import {
    ProjectsContainer,
    ScrollbarThumb,
    ProjectsTitle,
    GithubIcon,
    ProjectList,
    ProjectCard,
    ProjectName,
    ProjectDescription,
    ProjectStats,
    StatItem,
    StatLabel,
    StatCount,
    ProjectLink
} from './styled';

const Projects = () => {
    const [projects, setProjects] = useState([]);

    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: 0.1 }
        );

        sectionObserver.observe(sectionRef.current);

        return () => {
            sectionObserver.unobserve(sectionRef.current);
        };
    }, []);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('https://api.github.com/users/guizaramellaf91/repos');
                setProjects(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchProjects();
    }, []);

    return (
        <div ref={sectionRef}>
            <ProjectsContainer>
                <Fade bottom cascade when={isVisible}>
                    <ProjectsTitle>
                        <GithubIcon />Public Projects on GitHub
                    </ProjectsTitle>
                    <Scrollbars
                        style={{ width: '100%', height: '630px' }}
                        renderThumbVertical={({ style, ...props }) => (
                            <ScrollbarThumb {...props} style={style} />
                        )}
                    >
                        <ProjectList>
                            {projects.map((project) => (
                                <ProjectCard key={project.id}>
                                    <ProjectName>{project.name}</ProjectName>
                                    <ProjectDescription>{project.description}</ProjectDescription>
                                    <ProjectStats>
                                        <StatItem>
                                            <StatLabel>Language:</StatLabel>
                                            <StatCount>{project.language}</StatCount>
                                        </StatItem>
                                        <StatItem>
                                            <StatLabel>Stars:</StatLabel>
                                            <StatCount>{project.stargazers_count}</StatCount>
                                        </StatItem>
                                        <StatItem>
                                            <StatLabel>Forks:</StatLabel>
                                            <StatCount>{project.forks_count}</StatCount>
                                        </StatItem>
                                    </ProjectStats>
                                    <ProjectLink href={project.html_url} target="_blank" rel="noopener noreferrer">
                                        Visit Repository
                                    </ProjectLink>
                                </ProjectCard>
                            ))}
                        </ProjectList>
                    </Scrollbars>
                </Fade>
            </ProjectsContainer>
        </div>
    );
};

export default Projects;
