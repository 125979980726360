import React, { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import avatar from '../../assets/avatar.jpg';
import { HeaderContainer, AvatarImage, Title, Subtitle } from './styles';

const Header = () => {
  const name = 'Guilherme Zaramella Furtuoso';
  const grayColor = '#808080';
  const spanRefs = useRef([]);
  useEffect(() => {
    let currentColorIndex = 0;
    const interval = setInterval(() => {
      spanRefs.current[currentColorIndex].style.color = null;
      currentColorIndex++;
      if (currentColorIndex === name.length) {
        clearInterval(interval);
        return;
      }
      spanRefs.current[currentColorIndex].style.color = grayColor;
    }, 50);

    return () => clearInterval(interval);
  }, [name, grayColor]);

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionObserver.observe(sectionRef.current);

    return () => {
      sectionObserver.unobserve(sectionRef.current);
    };
  }, []);

  return (
    <div ref={sectionRef}>
      <HeaderContainer>
        <Fade bottom cascade when={isVisible}>
          <AvatarImage src={avatar} />
          <Title>
            {name.split('').map((letter, index) => (
              <span ref={el => (spanRefs.current[index] = el)} key={index}>
                {letter}
              </span>
            ))}
          </Title>
          <Subtitle>Fullstack Developer</Subtitle>
        </Fade>
      </HeaderContainer>
    </div>
  );
};

export default Header;
