import styled from 'styled-components';
import { FaGithub } from 'react-icons/fa';

export const ProjectsContainer = styled.div`
  margin-top: 100px;
`;

export const ScrollbarThumb = styled.div`
  background-color: #66ccff;
  border-radius: 4px;
`;

export const GithubIcon = styled(FaGithub)`
  margin-right: 10px;
`;

export const ProjectsTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #333333;
`;

export const ProjectList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ProjectCard = styled.li`
  background-color: #222222;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 5px;
  margin-right: 20px;
`;

export const ProjectName = styled.h2`
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 8px;
`;

export const ProjectDescription = styled.p`
  font-size: 16px;
  color: #cccccc;
  margin-bottom: 16px;
`;

export const ProjectStats = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const StatItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const StatLabel = styled.span`
  font-size: 14px;
  color: #cccccc;
  margin-right: 4px;
`;

export const StatCount = styled.span`
  font-size: 14px;
  color: #ffffff;
`;

export const ProjectLink = styled.a`
  color: #66ccff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
