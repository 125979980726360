import React, { useState, useEffect } from 'react';
import { Quote, SummaryContainer, Text } from './styles';

const Summary = () => {
  const [quoteText, setQuoteText] = useState('');
  const [summaryText, setSummaryText] = useState('');

  useEffect(() => {
    const quote = `"O espaço entre a teoria e a prática não é tão grande como é, a teoria na prática."`;
    const quoteArr = quote.split('');
    let currentQuoteText = '';

    const typeQuoteText = () => {
      if (quoteArr.length > 0) {
        currentQuoteText += quoteArr.shift();
        setQuoteText(currentQuoteText);
        setTimeout(typeQuoteText, 50);
      }
    };

    setTimeout(typeQuoteText, 500);
  }, []);

  useEffect(() => {
    const summary = `Tenho experiência em desenvolvimento de software, abrangendo desde a criação de projetos do zero até a manutenção e aprimoramento de projetos existentes. Além disso, possuo vivência com metodologias ágeis de desenvolvimento de software.
    Possuo conhecimento dos princípios de engenharia de software, incluindo design de arquitetura, testes, documentação e gerenciamento de configuração. Tenho habilidades em trabalhar em equipe, o que me permite comunicar e colaborar efetivamente com outros desenvolvedores e equipes.
    Também estou familiarizado com os princípios das boas práticas de desenvolvimento de software, como segurança, escalabilidade e desempenho. Tenho conhecimento dos desafios e problemas comuns enfrentados no desenvolvimento de software, bem como das melhores práticas para lidar com eles.
    Mantenho-me atualizado sobre as tendências tecnológicas atuais e sou capaz de aplicar esse conhecimento ao desenvolvimento de software, buscando sempre utilizar as melhores soluções disponíveis.`;
    const summaryArr = summary.split('');
    let currentSummaryText = '';

    const typeSummaryText = () => {
      if (summaryArr.length > 0) {
        currentSummaryText += summaryArr.shift();
        setSummaryText(currentSummaryText);
        setTimeout(typeSummaryText, 35);
      }
    };

    setTimeout(typeSummaryText, 6000);
  }, []);

  return (
    <>
      <Quote>{quoteText}</Quote>
      <SummaryContainer>
        <Text>{summaryText}</Text>
      </SummaryContainer>
    </>
  );
};

export default Summary;
