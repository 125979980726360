import styled from 'styled-components';

export const HeaderContainer = styled.header`
  text-align: center;
  margin-bottom: 40px;
`;

export const AvatarImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 16px;
`;

export const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 16px;
  position: relative;
`;

export const Subtitle = styled.p`
  font-size: 24px;
  margin-bottom: 24px;
`;