import React, { useState, useRef, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from 'react-reveal/Fade';
import { skills } from './datas';
import {
  SkillsContainer,
  ScrollbarThumb,
  SkillsIcon,
  SkillsTitle,
  SkillItem,
  SkillName,
  SkillBarContainer,
  SkillBar,
} from './styles';

const Skills = () => {
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const maxProgress = Math.max(...skills.map((language) => language.progress));

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionObserver.observe(sectionRef.current);

    return () => {
      sectionObserver.unobserve(sectionRef.current);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= maxProgress) {
          clearInterval(timer);
          setLoaded(true);
        }
        return newProgress;
      });
    }, 1);

    return () => {
      clearInterval(timer);
    };
  }, [maxProgress]);

  const sortedSkills = [...skills].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div ref={sectionRef}>
      <SkillsContainer>
        <Fade bottom cascade when={isVisible}>
          <SkillsTitle><SkillsIcon />Technical knowledge | Experiences</SkillsTitle>
          <Scrollbars
            style={{ width: '100%', height: '500px' }}
            renderThumbVertical={({ style, ...props }) => (
              <ScrollbarThumb {...props} style={style} />
            )}
          >
            {sortedSkills.map((language, index) => (
              <SkillItem key={index}>
                <SkillName>{language.name}</SkillName>
                {loaded ? (
                  <SkillBarContainer>
                    <SkillBar
                      progress={(language.progress / maxProgress) * 100}
                      style={{ width: `${(language.progress / maxProgress) * 100}%` }}
                    />
                  </SkillBarContainer>
                ) : (
                  <SkillBarContainer>
                    <SkillBar
                      progress={(progress / maxProgress) * 100}
                      style={{ width: `${(progress / maxProgress) * 100}%` }}
                    />
                  </SkillBarContainer>
                )}
              </SkillItem>
            ))}
          </Scrollbars>
        </Fade>
      </SkillsContainer>
    </div>
  );
};

export default Skills;
