import React, { useState, useRef, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { FiSmartphone, FiMail, FiLinkedin, FiGithub } from 'react-icons/fi';
import {
  ContactContainer,
  ContactIconTitle,
  ContactTitle,
  ContactItem,
  ContactIcon,
  ContactLabel,
  ContactValue
} from './styles';

const Contact = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionObserver.observe(sectionRef.current);

    return () => {
      sectionObserver.unobserve(sectionRef.current);
    };
  }, []);
  return (
    <div ref={sectionRef}>
      <ContactContainer>
        <Fade bottom cascade when={isVisible}>
          <ContactTitle><ContactIconTitle />Contacts</ContactTitle>
          <ContactItem>
            <ContactIcon>
              <FiSmartphone />
            </ContactIcon>
            <ContactLabel>Celular:</ContactLabel>
            <ContactValue>(31)98010-5687</ContactValue>
          </ContactItem>
          <ContactItem>
            <ContactIcon>
              <FiMail />
            </ContactIcon>
            <ContactLabel>E-mail:</ContactLabel>
            <ContactValue>guizaramellaf91@gmail.com</ContactValue>
          </ContactItem>
          <ContactItem>
            <ContactIcon>
              <FiLinkedin />
            </ContactIcon>
            <ContactLabel>Linkedin:</ContactLabel>
            <ContactValue href="https://www.linkedin.com/in/guizaramellaf/" target="_blank" rel="noopener noreferrer">
              https://www.linkedin.com/in/guizaramellaf/
            </ContactValue>
          </ContactItem>
          <ContactItem>
            <ContactIcon>
              <FiGithub />
            </ContactIcon>
            <ContactLabel>Github:</ContactLabel>
            <ContactValue href="https://github.com/guizaramellaf91" target="_blank" rel="noopener noreferrer">
              https://github.com/guizaramellaf91
            </ContactValue>
          </ContactItem>
        </Fade>
      </ContactContainer>
    </div>
  );
};

export default Contact;
