import Header from './components/Header';
import Summary from './components/Summary';
import Skills from './components/Skills';
import Projects from './components/Projects';
import DeployedProjects from './components/DeployedProjects';
import Cases from './components/Cases';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { AppContainer, GlobalStyle, ContentWrapper, MainContent } from './styles';

function App() {
  return (
    <AppContainer>
      <GlobalStyle />
      <ContentWrapper>
        <Header />
        <MainContent>
          <Summary />
          <Skills />
          <Projects />
          <DeployedProjects />
          <Cases />
          <Contact />
          <Footer />
        </MainContent>
      </ContentWrapper>
    </AppContainer>
  );
}

export default App;
