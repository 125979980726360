import React, { useState, useRef, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import {
    DeployedProjectsContainer,
    DeployedProjectsCard,
    ExternalIcon,
    DeployedProjectsTitle,
    DeployedProjectsDescription,
    DeployedProjectsLink
} from './styles';

const deployedProjects = [
    {
        title: 'Espaço Maljj',
        description: 'Aplicação para gestão e controle de reservas de locação',
        link: 'https://espacomaljj.com.br/',
    },  
    {
        title: 'Espaço Gourmet Viena',
        description: 'Aplicação para gestão e controle de reservas de locação',
        link: 'https://espacogourmetviena.com.br/',
    },
    {
        title: 'GUILL Control',
        description: 'Aplicação para gestão financeira no ramo de vendas (óculos)',
        link: 'https://guillcontrol.netlify.app/',
    },
    {
        title: 'Film Gallery',
        description: 'Portal para visualizar detalhes sobre filmes, pré-visualizar trailers e adicionar aos favoritos os melhores filmes',
        link: 'https://zaratechmovies.netlify.app/',
    },
];

const DeployedProjects = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: 0.1 }
        );

        sectionObserver.observe(sectionRef.current);

        return () => {
            sectionObserver.unobserve(sectionRef.current);
        };
    }, []);

    return (
        <div ref={sectionRef}>
            <DeployedProjectsContainer>
                <Fade bottom cascade when={isVisible}>
                    <DeployedProjectsTitle>
                        <ExternalIcon />Deployed projects
                    </DeployedProjectsTitle>
                    <div>
                        {deployedProjects.map((project, index) => (
                            <DeployedProjectsCard key={index}>
                                <DeployedProjectsTitle>{project.title}</DeployedProjectsTitle>
                                <DeployedProjectsDescription>{project.description}</DeployedProjectsDescription>
                                <DeployedProjectsLink href={project.link} target="_blank" rel="noopener noreferrer">
                                    Preview
                                </DeployedProjectsLink>
                            </DeployedProjectsCard>
                        ))}
                    </div>
                </Fade>
            </DeployedProjectsContainer>
        </div>
    );
};

export default DeployedProjects;
