import styled from 'styled-components';
import { FaSkiingNordic } from 'react-icons/fa';

export const SkillsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 250px;
  width: 100%;
  max-height: 200px;
`;

export const ScrollbarThumb = styled.div`
  background-color: #66ccff;
  border-radius: 4px;
`;

export const SkillsIcon = styled(FaSkiingNordic)`
  margin-right: 10px;
`;

export const SkillsTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #333333;
  
`;

export const SkillItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const SkillName = styled.span`
  font-size: 18px;
  margin-right: 12px;
  color: #cccccc;
  cursor: pointer;
`;

export const SkillBarContainer = styled.div`
  flex: 1;
  background-color: #333333;
  height: 8px;
  border-radius: 4px;
  margin-right: 20px;
`;

export const SkillBar = styled.div`
  background-color: #66ccff;
  height: 100%;
  border-radius: 4px;
  width: ${({ progress }) => progress}%;
  transition: width 500ms;
`;

export const SkillProgress = styled.span`
  color: #ffffff;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 16px;
`;
