import styled from 'styled-components';
import { FiExternalLink } from 'react-icons/fi';

export const DeployedProjectsContainer = styled.div`
  margin-top: 0px;
`;

export const DeployedProjectsCard = styled.div`
  background-color: #222222;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  margin-right: 20px;
`;

export const ExternalIcon = styled(FiExternalLink)`
  margin-right: 10px;
`;

export const DeployedProjectsTitle = styled.h3`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #333333;
  margin-bottom: 26px;
`;

export const DeployedProjectsDescription = styled.p`
  font-size: 16px;
  color: #555555;
  margin-bottom: 10px;
`;

export const DeployedProjectsLink = styled.a`
  display: block;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
