import React, { useState, useRef, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  CasesContainer,
  ScrollbarThumb,
  CasesIcon,
  CasesTitle,
  Timeline,
  TimelineItem,
  CaseItem,
  CaseName,
  CaseCompany,
  CaseYear,
  CaseDescription
} from './styles';

const cases = [
  {
    name: 'CARD',
    company: 'SMARF TECNOLOGY',
    year: 2023,
    description: 'Aplicação completa para gestão financeira, conciliação financeira e auditoria de transações. Aplicação desenhada e desenvolvida utilizando toda tecnologia envolvendo python, javascript(ts) e tecnologias parceiras como AWS.'
  },
  {
    name: 'OPX-FLOW',
    company: 'EQUALS',
    year: 2023,
    description: 'O OPX-Flow é uma aplicação para orquestração de fluxos e processos que substituiu a ferramenta Conductor da Netflix. O projeto é composto por um backend em Python e um frontend em ReactJS com TypeScript.'
  },
  {
    name: 'RECOVER-FILES',
    company: 'EQUALS',
    year: 2023,
    description: 'Desenvolvido para automatização no processo de buscar por contratos não implantados no banco de dados e na sequência buscar pelos arquivos nas pastas de erro de cada adquirente/van. '
      + 'Um processo antes realizado manualmente agora desenhado e planejado em um fluxo de validação automático, como também a possibilidade de realizar a varredura manualmente através de uma interface gráfica intuitiva e/ou através do endpoint.'
  },
  {
    name: 'SERVERLESS AWS GATEWAY',
    company: 'DTI DIGITAL',
    year: 2022,
    description: 'Gateway desenvolvido em NodeJS para atender à novas requisições vindas através do Sensedia e gerencia-las à nivel de registro em banco relacional e não-relacional. '
      + '(Oracle / Bucket S3). Parametrizações e monitoramento de filas (SQS) de acordo com os dados transitados entre as plataformas'
  },
  {
    name: 'RJ TOOLS',
    company: 'RJ CONSULTORES',
    year: 2021,
    description: 'Aplicação desenvolvida em Java (Spring Boot + Thymeleaf) para gerenciamento dos clientes internos, '
      + 'centralizando todas as informações necessárias referentes aos nossos clientes no mesmo lugar, armazenando chaves e abrindo conexões direto aos servidores (ssh)'
  },
  {
    name: 'HELPCLASSIC',
    company: 'DATABIT',
    year: 2017,
    description: ' Aplicação desenvolvida em C#, atendendo à uma necessidade do sistema em atualizar automaticamente de acordo com uma versão upada em FTP. '
      + 'Duas versões foram distribuídas, uma para o servidor e outra para as estações de trabalho, em determinados clientes chegavam à mais de 30+. '
      + 'O sistema além de monitorar, gerar logs, registros e alterações em banco, também tinha sua própria atualização automática, podendo contar com melhorias em massa com facilidade.'
  },
  {
    name: 'RADIOHELP',
    company: 'RADIO MEMORY',
    year: 2014,
    description: 'Elaborado um pseudo código para controle de versões e gestão da aplicação principal. Protótipo desenvolvido em C# e serviu de fonte de estudos para elaboração de projetos.'
  },
];

const Cases = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionObserver.observe(sectionRef.current);

    return () => {
      sectionObserver.unobserve(sectionRef.current);
    };
  }, []);

  return (
    <div ref={sectionRef}>
      <CasesContainer>
        <Fade bottom cascade when={isVisible}>
          <CasesTitle><CasesIcon />Cases | Soluctions</CasesTitle>
          <Scrollbars
            style={{ width: '100%', height: '630px' }}
            renderThumbVertical={({ style, ...props }) => (
              <ScrollbarThumb {...props} style={style} />
            )}
          >
            <Timeline>
              {cases.map((caseItem, index) => (
                <TimelineItem key={index} even={index % 2 === 0}>
                  <CaseItem>
                    <CaseName>{caseItem.name}</CaseName>
                    <CaseCompany>{caseItem.company}</CaseCompany>
                    <CaseYear>{caseItem.year}</CaseYear>
                    <CaseDescription>{caseItem.description}</CaseDescription>
                  </CaseItem>
                </TimelineItem>
              ))}
            </Timeline>
          </Scrollbars>
        </Fade>
      </CasesContainer>
    </div>
  );
};

export default Cases;
