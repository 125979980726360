import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

export const ContactContainer = styled.div`
  margin-top: 10px;
`;

export const ContactIconTitle = styled(FaCheck)`
  margin-right: 10px;
`;

export const ContactTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #333333;
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const ContactIcon = styled.span`
  margin-right: 8px;
  font-size: 18px;
  color: #66ccff;
`;

export const ContactLabel = styled.span`
  font-size: 16px;
  margin-right: 8px;
  color: #ffffff;
  min-width: 100px;
`;

export const ContactValue = styled.a`
  font-size: 16px;
  color: #66ccff;
  text-decoration: none;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
  }
`;
