import React from 'react';
import Fade from 'react-reveal/Fade';
import { FooterContainer, FooterText } from './styles';

const Footer = () => {
  return (
    <FooterContainer>
      <Fade bottom cascade>
        <FooterText>© 2023 Guilherme Zaramella Furtuoso. Todos os direitos reservados.</FooterText>
      </Fade>
    </FooterContainer>
  );
};

export default Footer;
