import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: #f8f8f8;
  padding: 16px;
  margin-top: auto;
  border-radius: 5px;
`;

export const FooterText = styled.p`
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 0;
`;
