import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

export const AppContainer = styled.div`
  background-color: #121212;
  color: #cccccc;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  max-width: 900px;
  min-width: 900px;
  padding: 40px;

  @media (max-width: 1024px) {
    max-width: auto;
    min-width: auto;
  }

  @media (max-width: 768px) {
    max-width: auto;
    min-width: auto;
  }

  @media (max-width: 480px) {
    max-width: auto;
    min-width: auto;
  }
`;

export const MainContent = styled.div`
  display: grid;
  grid-gap: 40px;
`;
