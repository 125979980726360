import styled from 'styled-components';
import { FaCapsules } from 'react-icons/fa';

export const CasesContainer = styled.div`
  margin-top: 10px;
`;

export const ScrollbarThumb = styled.div`
  background-color: #66ccff;
  border-radius: 4px;
`;

export const CasesIcon = styled(FaCapsules)`
  margin-right: 10px;
`;

export const CasesTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #333333;
`;

export const Timeline = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-right: 20px;
`;

export const TimelineItem = styled.li`
  display: flex;
  flex-direction: ${({ even }) => (even ? 'row-reverse' : 'row')};
  align-items: center;
  margin-bottom: 24px;
`;

export const CaseItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ even }) => (even ? 'flex-end' : 'flex-start')};
`;

export const CaseName = styled.h3`
  font-size: 18px;
  margin-bottom: 4px;
`;

export const CaseCompany = styled.span`
  color: #666666;
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
`;

export const CaseYear = styled.span`
  color: #666666;
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
`;

export const CaseDescription = styled.p`
  font-size: 16px;
  color: #333333;
`;
